const en = {
  "Contact us": "Contact us",
  "Your email address": "Your email address",
  "Your message": "Your message",
  Send: "Send",
  "New message from your Zuv site": "New message from your Zuv site",
};

const fr = {
  "Contact us": "Contactez nous",
  "Your email address": "Votre e-mail",
  "Your message": "Votre message",
  Send: "Envoyer",
  "New message from your Zuv site": "Nouveau message de votre site Zuv",
} satisfies { [K in keyof typeof en]: string };

export const I18n = (lang: "en" | "fr" | string = "en"): typeof en =>
  ({ en, fr }[lang] || en);
